import {FormikProps} from 'formik'
interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
    touched?: boolean | undefined
    error?: string | undefined
    name: string
    label?: string
    type?: string
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
    onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void
    required?: boolean
    formik?: FormikProps<any>
    className?: string
}
export function CheckBox({
    touched,
    error,
    name,
    label,
    type = 'text',
    onChange,
    onBlur,
    required = false,
    formik,
    className,
    checked,
    ...props
}: Props) {
    function getValue() {
        if (checked === undefined) {
            return formik?.values[name]
        }

        return checked
    }

    return (
        <div className={`d-flex form-check form-check-custom form-check-solid ${className}`}>
            <input
                {...props}
                className='form-check-input'
                type='checkbox'
                id={name}
                name={name}
                // onChange={onChange || formik?.handleChange}
                onBlur={onBlur || formik?.handleBlur}
                checked={getValue()}
                onChange={() => formik?.setFieldValue(name, !formik.values[name])}
            />
            {label && (
                <label
                    htmlFor={name}
                    className='form-label fs-6 fw-bolder m-0 h-100 d-flex align-items-center ms-2'
                >
                    {label}
                    {required && <span className='text-danger'>*</span>}
                </label>
            )}
        </div>
    )
}
